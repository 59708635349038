import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import store from "../store";

import NotFound from '@/views/NotFoundPage.vue';
import List from "../views/Pages/Clinics/List";

const routes = [
  {
    path: '/',
    redirect: 'clinics',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {name_ru: 'Статистика'},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue')
      },
      {
        path: '/profile',
        name: 'profile',
        meta: {name_ru: 'Профиль'},
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/UserProfile.vue')
      },
      {
        path: '/clinics',
        name: 'clinics',
        meta: {name_ru: 'Клиники'},
        component: () => import('../views/Pages/Clinics/List')
      },
      {
        path: '/clinics/:clinic',
        name: 'clinic',
        meta: {name_ru: 'Клиника'},
        component: () => import('../views/Pages/Clinics/Get')
      },
      {
        path: '/doctors',
        name: 'doctors',
        meta: {name_ru: 'Врачи'},
        component: () => import('../views/Pages/Doctors/List')
      },
      {
        path: '/doctor/:doctor',
        name: 'doctor',
        meta: {name_ru: 'Врач'},
        component: () => import('../views/Pages/Doctors/Get')
      },
      {
        path: '/symptoms',
        name: 'symptoms',
        meta: {name_ru: 'Симптомы'},
        component: () => import('../views/Pages/Symptoms/List')
      },
      {
        path: '/symptoms/:symptom',
        name: 'symptom',
        meta: {name_ru: 'Симптом'},
        component: () => import('../views/Pages/Symptoms/Get')
      },
      {
        path: '/specialties',
        name: 'specialties',
        meta: {name_ru: 'Специализации'},
        component: () => import('../views/Pages/Specialties/List')
      },
      {
        path: '/clinic/specialties',
        name: 'specialties_clinic',
        meta: {name_ru: 'Специализации клиник'},
        component: () => import('../views/Pages/ClinicSpecialty/ClinicSpecialtyList')
      },
      {
        path: '/specialties/:specialty',
        name: 'specialty',
        meta: {name_ru: 'Специализация'},
        component: () => import('../views/Pages/Specialties/Get')
      },
      {
        path: '/patients',
        name: 'patients',
        meta: {name_ru: 'Пациенты'},
        component: () => import('../views/Pages/Patients/List')
      },
      {
        path: '/patients/:patient',
        name: 'patient',
        meta: {name_ru: 'Пациент'},
        component: () => import('../views/Pages/Patients/Get')
      },
      {
        path: '/emergency',
        name: 'emergency',
        meta: {name_ru: 'Заявки на скорую помощь'},
        component: () => import('../views/Pages/EmergencyCalls/List')
      },
      {
        path: '/emergency/:emergency',
        name: 'emergency get',
        meta: {name_ru: 'Заявка на скорую помощь'},
        component: () => import('../views/Pages/EmergencyCalls/Get')
      },
      {
        path: '/feedbacks',
        name: 'feedback',
        meta: {name_ru: 'Отзывы'},
        component: () => import('../views/Pages/Feedbacks/List')
      },
      {
        path: '/categories',
        name: 'categories',
        meta: {name_ru: 'Категории услуг'},
        component: () => import('../views/Pages/ServiceCategories/List')
      },
      {
        path: '/analyses',
        name: 'analyses',
        meta: {name_ru: 'Анализы'},
        component: () => import('../views/Pages/Analyses/List')
      },
      {
        path: '/references/groups',
        name: 'references_groups',
        meta: {name_ru: 'Реф. Группы'},
        component: () => import('../views/Pages/ReferencesGroup/List')
      },
      {
        path: '/clinics/:clinic/templates/create',
        name: 'templates_create',
        meta: {name_ru: 'Создать шаблон'},
        component: () => import('../views/Pages/Clinics/components/InspectionTemplates/CreateInspectionTemplate')
      },
      {
        path: '/clinics/:clinic/templates/:template',
        name: 'templates_update',
        meta: {name_ru: 'Обновление шаблона'},
        component: () => import('../views/Pages/Clinics/components/InspectionTemplates/UpdateInspectionTemplate')
      },
      {
        path: '/clinics/:clinic/laboratory/templates/create',
        name: 'laboratory_templates_create',
        meta: {name_ru: 'Создать шаблон'},
        component: () => import('../views/Pages/Clinics/components/LaboratoryTemplate/components/LaboratoryTemplateCreate')
      },
      {
        path: '/clinics/:clinic/laboratory/templates/:template',
        name: 'laboratory_templates_update',
        meta: {name_ru: 'Обновить шаблон'},
        component: () => import('../views/Pages/Clinics/components/LaboratoryTemplate/components/LaboratoryTemplateUpdate')
      },
      {
        path: '/states',
        name: 'states',
        meta: {name_ru: 'Состояние пациента'},
        component: () => import('../views/Pages/UserStates/List')
      },
      {
        path: '/vaccines',
        name: 'vaccines',
        meta: {name_ru: 'Вакцины'},
        component: () => import('../views/Pages/Vaccines/ListVaccines')
      },
      {
        path: '/cities',
        name: 'cities',
        meta: {name_ru: 'Города'},
        component: () => import('../views/Pages/Cities/Cities')
      },
      {
        path: '/districts',
        name: 'districts',
        meta: {name_ru: 'Районы'},
        component: () => import('../views/Pages/Districts/Districts')
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.status.loggedIn) next()
      else next({name: 'dashboard'})
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Register.vue')
      },
      {path: '*', component: NotFound}
    ]
  }
];

export default routes;
